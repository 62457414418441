.slide-content {
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.swiper-slide {
    padding: 40px 20px;
    display: flex;
    justify-content: center;
}

.slide-content .content-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.slide-content .slide-title {
    position: relative;
}
.slide-content .slide-title h2 {
    font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
    font-size: 24px;
    text-align: left;
    line-height: 1.2;
    color: #ffffff;
    position: relative;
}

.slide-content .scan-img {
    margin-bottom: 20px;
    padding: 0px;
    background: rgb(0,0,37);
background: linear-gradient(180deg, rgba(0,0,37,1) 0%, rgba(0,0,37,0) 80%);
}

.slide-content .slide-title h2::after {
    content:'';
    display: inline-block;
    bottom: -8px;
    left: 0;
    background: rgb(255,108,14);
    background: linear-gradient(90deg, rgba(255,108,14,1) 0%, rgba(209,87,113,1) 40%, rgba(148,58,244,1) 100%);
    height: 4px;
    width: 80px;
    position: absolute;
    z-index: -1;
  }


.slide-content .nav {
    display: flex;
    margin-bottom: 20px;
}
.slide-content .next > img {
    margin-left: 3px;
}
.slide-content .prev > img {
    margin-right: 3px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}



@media screen and (max-width: 1220px) {
  
    .slide-content {
        max-width: 720px;
    }

  }