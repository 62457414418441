/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: DIN 2014 Regular by ParaType
 * URL: https://www.myfonts.com/collections/din-2014-font-paratype
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: DIN 2014 Bold by ParaType
 * URL: https://www.myfonts.com/collections/din-2014-font-paratype
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2023 MyFonts Inc. */


  
@font-face {
  font-family: "DIN2014Bold";
  src: url('DIN2014Bold/font.woff2') format('woff2'), url('DIN2014Bold/font.woff') format('woff');
}
@font-face {
  font-family: "DIN2014Regular";
  src: url('DIN2014Regular/font.woff2') format('woff2'), url('DIN2014Regular/font.woff') format('woff');
}

