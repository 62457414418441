.swiper-holder {
    max-width: 68%;
    /* background-color: rgba(255, 255, 255, 0.3); */
}
.library {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
}
.oneslide {
    width: 100%;
}

h1 {
    font-size: 36px;
    line-height: 1.2;
    color: #FF6C0E;
    text-align: center;
}

p {
    font-size: 24px;
    text-align: center;
}

.swiper-autoheight .swiper-wrapper {
    /* min-height: 100vh !important; */
    height: fit-content !important;
}

.library-right-col {
    width: 30%;
    background-color: rgba(0, 0, 42, .1);
    padding: 30px 40px 40px 40px;
}
.library-right-col h2 {
    font-size: 24px;
    margin-bottom: 5px;
    margin-top: 20px;
}
.library-right-col h2:first-child {
    margin-top: 0px;
}
.library-right-col ul li {
    font-size: 16px;
    line-height: 1.2;
    color: #ffffff;
    margin-bottom: 5px;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Ccircle class='st0' cx='3' cy='5' r='3'/%3E%3C/svg%3E");

}


@media only screen and (max-width: 1200px) {

    h1 {
        font-size: 36px;
        text-align: center;
    }
    p {
        font-size: 24px;
    }
    .question-wrap {
        max-width: 1024px;
    }
    .footnote-wrap {
        max-width: 1024px;
    }

}

@media only screen and (max-width: 1200px) {

    h1 {
        font-size: 36px;
        text-align: center;
    }
    p {
        font-size: 24px;
    }
    .question-wrap {
        max-width: 960px;
    }
    .footnote-wrap {
        max-width: 960px;
    }

}



@media only screen and (max-width: 768px) {

    .library {
        flex-direction: column;
    }

    .swiper-holder {
        width: 100%;
        max-width: 100%;
    }
    .library-right-col {
        width: 100%;
        padding: 20px;
    }
    .library-right-col h2 {
        margin-top: 0px;
    }

}