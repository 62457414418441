/* Comparison */

#comparison .modal-header-transparent {
    width: 100%;
    position: fixed;
    top: 0;
}

#comparison .comparison-wrap {
    width: 100%;
}
#comparison .column-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
#comparison .comparison-col img {
    width: 100%;
    display: block;
}
#comparison .day img {
    width: 30px;
    display: block;
}
#comparison .left-col {
    width: 46%;
    background-color: transparent;
}
#comparison .right-col {
    width: 46%;
    background-color: transparent;
}
#comparison .footnote {
    margin-top: 40px;
}
#comparison .key {
    padding-left: 23px;
    background-image: url('../../assets/images/arrow-yellow.png');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 20px;
    margin: 10px 0px;
}
#comparison .key p {
    text-align: left;
    padding-top: 5px;
    margin-bottom: 0px;
}
#comparison h2 {
    font-size: 22px;
}
#comparison ul {
    margin-top: 10px;
}
#comparison ul li {
    font-size: 16px;
    color: #FFFFFF;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Ccircle class='st0' cx='3' cy='5' r='3'/%3E%3C/svg%3E");
}
#comparison p {
    font-size: 14px;
    color: #FFFFFF;
}
.mob-text1, .mob-text2 {
    display: none;
    width: 100%;
    margin: 15px 0px 20px 0px;
}
.mobile-day-img {
    display: none !important;
}
.desktop-day-img {
    display: block !important;
}





@media only screen and (max-width: 768px) {

    #comparison .column-wrap {
        flex-direction: column;
    }

    #comparison .left-col {
        width: 100%;
        max-width: 580px;
    }
    #comparison .right-col {
        width: 100%;
        max-width: 580px;
    }
    #comparison .desktop-column-wrap {
        display: none;
    }
    #comparison .footnote {
        margin-top: 0px;
    }
    .mob-text1, .mob-text2 {
        display: block;
    }
    .mobile-day-img {
        width: 130px !important;
        display: block !important;
        margin: 10px auto 20px auto;

    }
    .desktop-day-img {
        display: none !important;
    }

}