footer {
    width: 100%;
    display: flex;
    justify-content: center;
    /* padding: 24px; */
    /* position: fixed;
    left: 0px;
    bottom: 0px; */
    /* background-color: rgba(0, 47, 85, .8) */
    background-color: #002F55;
}
.footer-wrap {
    width: 100%;
}
.footer-footnote-wrap {
    width: 100%;
    /* max-width: 1920px; */
    display: flex;
    justify-content: space-between;
    align-items: ;
}
.footer-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items:flex-end;
    /* margin-top: 15px; */
    padding: 15px 20px;
    background-color: rgba(0, 0, 37, .3);
}
.footer-bar p {
    font-size: 14px;
    line-height: 1.2;
    text-align: right;
    margin-bottom: 0px;
}

.footer-bar a { color: #ffffff; }
.footer-bar a:link { color: #ffffff; }
.footer-bar a:visited { color: #ffffff; }
.footer-bar a:hover { color: #ffffff; }
.footer-bar a:active { color: #ffffff; }



.footer-bar .jobcode {
    color: #738FAF;
}
.footer-logo {
    width: 200px;
    margin-right: 20px;
}
.footer-text {
    padding: 0px 0px 0px 20px;
}
.footer-txt1 {
    max-width: 800px;
    padding: 0px 20px 0px 20px;
}
.footer-txt1 > p {
    text-align: left !important;
}
.footer-txt2 {
    padding: 0px 20px 0px 20px;
}
.footer-text p {
    font-size: 10px;
    text-align: right;
}
.footer-text p.ae {
    font-size: 10px;
    text-wrap: nowrap;
    /* text-align: right; */
    padding: 5px 10px;
    display: inline-block;
    border: 1px solid #ffffff;
}
.hide-footnote {
    display: none !important;
}


@media only screen and (max-width: 1200px) {

    .footer-btn {
        padding: 12px 20px;
        font-size: 14px;
    }

}

/* mobile */
@media only screen and (max-width: 1024px) {

    footer {
        position: relative;
        padding: 0px;
        background-color: #002F55;
    }
    .footer-btns {
        width: 100%;
        flex-direction: column;
        /* padding: 0px 0px; */

    }
    .footer-footnote-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .footer-btn {
        /* width: 100%;
        flex-direction: column; */
        padding: 14px 30px;
        margin-right: 0;
        margin-top: 10px;
    }
    .footer-bar {
        flex-direction: column;
        align-items: flex-start;
    }
    .footer-text p:first-child {
        margin-top: 5px;
    }
    .footer-text p {
        text-align: left;
        margin-bottom: 8px;
    }
    .footer-txt1 {
        max-width: unset;
    }

}