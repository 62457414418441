.submenu-wrap {
    width: 100%;
    min-width: 660px;
    min-height: 400px;
    display: flex;
    background-color: #FFFFFF;
}

.submenu-wrap .submenu-el {
    width: 50%;
} 

.submenu-wrap .text {
   padding: 30px;
} 
.submenu-wrap .text h1 {
   font-size: 28px;
   color: #002F55;
   text-align: left;
   margin-bottom: 10px;
} 
.submenu-wrap .text p {
   font-size: 20px;
   color: #002F55;
   text-align: left;
   margin-bottom: 0;
   -webkit-transition: all 200ms ease;
   -moz-transition: all 200ms ease;
   -ms-transition: all 200ms ease;
   -o-transition: all 200ms ease;
   transition: all 200ms ease;
   pointer-events: none;
} 
.submenu-item {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 8 8' style='enable-background:new 0 0 8 8;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23000025;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='1.2,7.8 0,6.7 2.7,4 0,1.3 1.2,0.2 5,4 '/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 12px 10px;
    background-position: left top 16px;
    padding: 8px 20px;

    cursor: pointer;
}
.submenu-item:hover > p {
    color: #943AF4;
    text-decoration: underline;
}
.submenu-wrap .submenu-el img {
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
} 
.submenu-wrap .fig-0 {
    background-image: url('../../assets/images/mod0-img.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
} 
.submenu-wrap .fig-1 {
    background-image: url('../../assets/images/mod1-img.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
} 
.submenu-wrap .fig-2 {
    background-image: url('../../assets/images/mod2-img.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
} 
.submenu-wrap .fig-3 {
    background-image: url('../../assets/images/mod3-img.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
} 
.submenu-wrap .fig-4 {
    background-image: url('../../assets/images/mod4-img.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
} 


@media only screen and (max-width: 768px) {

    #submenu {
        background-color: #FFFFFF;
    }
    .submenu-wrap {
      display: block;
    }

    .submenu-wrap {
        width: 100%;
        min-width: unset;
        min-height: 320px;
        background-color: transparent;
        display: flex;
    }

    /* .submenu-wrap {
        width: 100%;
        min-width: unset;
        min-height: unset;
        
    } */
    .submenu-wrap .submenu-el {
        width: 100%;
    }
    .submenu-wrap .image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: .2;
    }
    .submenu-wrap .text {
        z-index: 1;
    }
  
  }